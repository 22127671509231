@use '../../../scss/variable/font';
@use '../../../scss/variable/breakpoint';

.Heading {
  font-family: var(--theme-font-family-secondary);
  font-weight: var(--theme-heading-font-weight);
  color: var(--theme-font-color);

  &_isPrimary {
    font-family: var(--theme-font-family-primary);
  }

  &_isBold {
    font-weight: font.$weight-bold;
  }

  &_h1 {
    font-size: var(--theme-font-size-lg);

    @media screen and (min-width: breakpoint.$lg) {
      font-size: var(--theme-font-size-xl);
    }
  }

  &_h2 {
    font-size: var(--theme-font-size-md);

    @media screen and (min-width: breakpoint.$md) {
      font-size: var(--theme-font-size-lg);
    }
  }

  &_h3 {
    font-size: var(--theme-font-size-sm);

    @media screen and (min-width: breakpoint.$md) {
      font-size: var(--theme-font-size-md);
    }
  }

  &_h4 {
    font-size: var(--theme-font-size-xs);

    @media screen and (min-width: breakpoint.$md) {
      font-size: var(--theme-font-size-sm);
    }
  }

  &_h5 {
    font-size: calc(var(--theme-font-size-xs) - 2);

    @media screen and (min-width: breakpoint.$md) {
      font-size: var(--theme-font-size-xs);
    }
  }

  &_h6 {
    font-size: var(--theme-font-size-base);
  }
}

