@use '../../scss/settings/spacing';
@use '../../../scss/variable/font';

.ContentBlock {
  position: relative;

  &__subtitle {
    color: var(--theme-font-color);
    margin-bottom: spacing.$s2;
    font-weight: font.$weight-600;
  }

  &__content {
    color: var(--theme-font-color-light);

    &_small {
      font-size: var(--theme-font-size-xxs);
    }
  }
}
