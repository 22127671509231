/* stylelint-disable max-nesting-depth, selector-max-compound-selectors, declaration-no-important */
@use '../../../../scss/variable/breakpoint';
@use '../../../../scss/variable/color';
@use '../../../../stories/scss/settings/spacing';
@use '../../../../scss/variable/transition';

.ImageSlider {
  margin-right: calc(#{spacing.$s2} * -1);
  padding-left: spacing.$s2;
  @media screen and (min-width: breakpoint.$md) {
    padding-left: 0;
  }

  :global {
    .slider {
      padding: spacing.$s4 0;
      @media screen and (min-width: breakpoint.$md) {
        padding: spacing.$s8 0;
      }

      &__wrapper {
        margin: 0;
        padding: spacing.$s2 0;

        &__slide {
          margin-right: spacing.$s4;

          @media screen and (min-width: breakpoint.$md) {
            margin-right: spacing.$s6;

            &:last-child {
              margin-right: 0;
            }
          }

          .is-col-1 {
            align-items: flex-start;

            picture {
              display: block;
              width: 225px;
              height: 225px;

              @media screen and (min-width: breakpoint.$md) {
                width: 322px;
                height: 322px;
              }

              img {
                object-fit: cover;
                height: 100%;
              }
            }

            p {
              max-width: 207px;
              padding-left: spacing.$s2;
              @media screen and (min-width: breakpoint.$md) {
                padding-left: spacing.$s4;
              }
            }
          }
        }
      }

      &__button {
        @media screen and (min-width: breakpoint.$md) {
          margin: 0;
          top: -50px;
          position: absolute;
          display: flex;
          background-color: color.$base-60;
          transition: background-color transition.$default;

          &:hover {
            background-color: color.$base-70;
          }

          &__icon {
            fill: var(--theme-color-background);
          }
        }

        &--hidden {
          @media screen and (min-width: breakpoint.$md) {
            opacity: 0.2;
            cursor: not-allowed;

            &:hover {
              background-color: color.$base-60;
            }
          }
        }

        &__prev {
          @media screen and (min-width: breakpoint.$md) {
            right: 53px;
            left: unset;
          }
        }

        &__next {
          @media screen and (min-width: breakpoint.$md) {
            right: 0;
          }
        }
      }
    }
  }
}
