// Variable
@use '../../../scss/variable/color';
@use '../../../stories/scss/settings/spacing';
@use '../../../scss/variable/breakpoint';

.slider {
  &__button {
    margin: auto 0;
    height: 48px;
    width: 48px;
    background-color: var(--theme-color-white);

    &__next {
      &--horizontal {
        right: calc(#{spacing.$s2} * -1);

        @media screen and (min-width: breakpoint.$md) {
          right: calc(#{spacing.$s6} * -1);
        }
      }
    }

    &__prev {
      &--horizontal {
        left: calc(#{spacing.$s2} * -1);

        @media screen and (min-width: breakpoint.$md) {
          left: calc(#{spacing.$s6} * -1);
        }
      }
    }
  }

  &__wrapper {
    margin: spacing.$s4 0;
    padding-bottom: spacing.$s4;

    @media screen and (min-width: breakpoint.$md) {
      margin: spacing.$s6 0 0;
    }

    &__slide {
      margin-right: spacing.$s2;

      @media screen and (min-width: breakpoint.$md) {
        margin-left: 0;
        margin-right: spacing.$s6;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
