@use '../../../../stories/scss/settings/spacing';
@use '../../../../scss/variable/shadow';
@use '../../../../scss/mixin/typography';

.Box {
  $Box: &;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__content {
    display: flex;
    justify-content: center;
    text-align: center;

    > p {
      @include typography.typography-ellipses();
      margin-top: spacing.$s4;
    }
  }

  &__image {
    position: relative;
  }

  &__small {
    width: 140px;

    #{$Box}__image {

      picture {
        display: flex;
        overflow: hidden;
        border-radius: 50%;
      }

      &__button {
        position: absolute;
        bottom: 0;
        border: 0;
        right: spacing.$s2;
      }
    }
  }

  &__large {
    display: block;
    width: 100%;
    overflow: hidden;

    #{$Box}__image {
      box-shadow: shadow.$drop-bottom;
      border-radius: var(--theme-border-radius-sm);
      overflow: hidden;

      picture {
        img {
          display: block;
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
