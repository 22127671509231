@use '../../../../scss/variable/breakpoint';
@use '../../../../stories/scss/settings/spacing';

.Boxes {
  &__small {
    display: flex;
    column-gap: spacing.$s4;
    row-gap: spacing.$s8;

    @media screen and (min-width: breakpoint.$md) {
      justify-content: center;
      flex-wrap: wrap;
      column-gap: spacing.$s8;
    }
  }
}
