/* stylelint-disable max-nesting-depth, selector-max-compound-selectors */
@use '../../../../../scss/variable/breakpoint';
@use '../../../../../stories/scss/settings/spacing';
@use '../../../../../scss/mixin/image';

.ProductSlider {
  $ProductSlider: &;
  display: grid;
  grid-template-columns: 100%;
  padding: spacing.$s10 0;
  grid-template-areas: 'title'
    'slider'
    'button';

  @media screen and (min-width: breakpoint.$md) {
    padding: spacing.$s18 0;
  }

  &__title {
    grid-area: title;
  }

  &__button {
    grid-area: button;
  }

  &__slider {
    grid-area: slider;
    overflow: hidden;
    margin-right: calc(#{spacing.$s2} * -1);
    padding: spacing.$s6 0 spacing.$s10 0;

    @media screen and (min-width: breakpoint.$md) {
      padding: 0;
      margin-right: calc(#{spacing.$s6} * -1);
    }

    @media screen and (min-width: breakpoint.$lg) {
      margin-right: 0;
    }

    :global {
      .slider__wrapper {
        margin: 0;
        padding: 0;

        @media screen and (min-width: breakpoint.$lg) {
          display: grid;
          column-gap: spacing.$s6;
        }

        &__slide {
          margin-right: 14px;

          @media screen and (min-width: breakpoint.$lg) {
            margin-right: 0;
          }
        }
      }
    }
  }

  &__basic {
    @media screen and (min-width: breakpoint.$md) {
      grid-template-columns: auto 1fr;
      grid-template-rows: auto auto 0;
      grid-template-areas: 'title button'
        'slider slider';
    }

    #{$ProductSlider}__title {
      margin-bottom: spacing.$s2;
      text-align: center;

      @media screen and (min-width: breakpoint.$md) {
        margin: 0 0 spacing.$s10 spacing.$s4;
      }
    }

    :global {
      .slider__wrapper {
        @media screen and (min-width: breakpoint.$lg) {
          grid-template-columns: repeat(4, 1fr);
        }

        &__slide {
          .product-box {
            width: 240px;
            @media screen and (min-width: breakpoint.$lg) {
              width: 100%;
            }
          }
        }
      }
    }

    #{$ProductSlider}__button {
      @media screen and (min-width: breakpoint.$md) {
        margin: 0 spacing.$s4 0 auto;
      }
    }
  }

  &__advanced {
    @media screen and (min-width: breakpoint.$md) {
      grid-template-rows: auto auto 0;
      grid-template-columns: auto 1fr;
      grid-column-gap: spacing.$s4;
      grid-template-areas: 'title slider'
        'button slider';
    }

    #{$ProductSlider}__title {
      @media screen and (min-width: breakpoint.$md) {
        margin: 0 spacing.$s4 spacing.$s8 0;
      }

      &--wave {
        @media screen and (min-width: breakpoint.$md) {
          &:before {
            margin: spacing.$s6 0 spacing.$s8 0;
            @include image.background-image-pseudo(
              $_background-image: '/images/template/wave-hover-black.svg',
              $_background-image-height: 6px,
              $_background-image-width: 60px,
              $_background-size: initial
            );
          }

          &--red {
            &:before {
              background-image: url('/images/template/wave-hover.svg');
            }
          }

          &--green {
            &:before {
              background-image: url('/images/template/wave-hover-green.svg');
            }
          }

          &--yellow {
            &:before {
              background-image: url('/images/template/wave-hover-yellow.svg');
            }
          }

          &--blue {
            &:before {
              background-image: url('/images/template/wave-hover-blue.svg');
            }
          }
        }
      }
    }

    #{$ProductSlider}__slider {
      :global {
        .slider__wrapper {
          @media screen and (min-width: breakpoint.$lg) {
            grid-template-columns: repeat(3, 1fr);
          }

          &__slide {
            .product-box {
              width: 300px;

              @media screen and (min-width: breakpoint.$lg) {
                width: 100%;
              }
            }
          }
        }
      }
    }

    #{$ProductSlider}__button {
      @media screen and (min-width: breakpoint.$md) {
        grid-row: span 2;
      }
    }
  }
}

