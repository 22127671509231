@use '../../../../scss/variable/breakpoint';
@use '../../../../scss/variable/color';
@use '../../../../scss/variable/font';
@use '../../../../stories/scss/settings/spacing';

.Usps {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  $Usps: &;

  &__basic {
    row-gap: spacing.$s10;
    column-gap: spacing.$s8;

    @media screen and (min-width: breakpoint.$lg) {
      column-gap: spacing.$s10;
    }

    #{$Usps}__item {
      flex-basis: calc(50% - #{spacing.$s8});
      justify-content: center;
      flex-wrap: wrap;

      @media screen and (min-width: breakpoint.$lg) {
        flex-basis: calc(25% - #{spacing.$s10});
      }

      @media screen and (min-width: breakpoint.$xl) {
        flex-basis: 218px;
      }

      &__image {
        flex: 0 0 100%;
        justify-content: center;
        margin-bottom: spacing.$s4;
      }

      &__content {
        max-width: 218px;
        text-align: center;

        &__title {
          margin-bottom: spacing.$s2;
          @media screen and (min-width: breakpoint.$lg) {
            font-size: font.$size-base;
          }
        }
      }
    }
  }

  &__advanced {

    @media screen and (min-width: breakpoint.$md) {
      gap: spacing.$s10 spacing.$s6;
    }

    #{$Usps}__item {
      flex-basis: 100%;
      padding: 20px;
      display: none;

      &:first-child {
        padding-top: 0;
        display: flex;
        border-bottom: 1px solid var(--theme-color-base);

        + #{$Usps}__item {
          display: flex;
        }

        @media screen and (min-width: breakpoint.$lg) {
          padding: 30px;
          border: 0;
        }
      }

      @media screen and (min-width: breakpoint.$lg) {
        display: flex;
        border-radius: var(--theme-border-radius-sm);
        padding: 30px;
        background-color: color.$bg-grey;
        max-width: 289px;
        flex-basis: calc(25% - #{spacing.$s6});
      }

      &__image {
        margin-right: 20px;

        picture {
          width: 20px;
        }
      }

      &__content {
        &__title {
          margin: 2px 0;
        }
      }

      &__button__link {
        display: flex;
        align-items: center;
        margin-left: auto;
        transform: scale(-1);

        @media screen and (min-width: breakpoint.$lg) {
          display: none;
        }
      }
    }
  }

  &__item {
    display: flex;

    @at-root a#{$Usps}__item {
      display: flex;
    }

    &__image {
      display: flex;
    }

    &__content {
      display: flex;
      flex-direction: column;
      font-size: font.$size-xxs;

      &__title {
        font-weight: font.$weight-600;
      }

      &__subtitle {
        color: var(--theme-font-color-light);
      }
    }
  }
}
