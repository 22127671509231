/* stylelint-disable max-nesting-depth, selector-max-compound-selectors, declaration-no-important */
@use '../../../../scss/variable/breakpoint';
@use '../../../../scss/variable/color';
@use '../../../../stories/scss/settings/spacing';
@use '../../../../scss/variable/transition';
@use '../../../../scss/variable/font';

.BoxSlider {
  padding: spacing.$s10 0 spacing.$s4 0;
  margin-right: calc(#{spacing.$s2} * -1);

  @media screen and (min-width: breakpoint.$md) {
    padding: spacing.$s18 0 spacing.$s10 0;
    margin-right: 0;
  }

  &__title {
    padding: 0 spacing.$s4 0 spacing.$s4;
    @media screen and (min-width: breakpoint.$md) {
      position: absolute;
    }
  }

  :global {
    .slider {
      padding: spacing.$s10 0;

      &__wrapper {
        margin-top: 0;
        padding: 0;

        @media screen and (min-width: breakpoint.$md) {
          padding: spacing.$s10 0;
        }

        &__slide {
          margin-right: spacing.$s4;

          @media screen and (min-width: breakpoint.$md) {
            margin-right: spacing.$s6;

            &:last-child {
              margin-right: 0;
            }
          }

          .is-col-1 {
            align-items: flex-start;

            picture {
              align-items: center;
              display: flex;
              width: 240px;
              height: 288px;
              background-color: var(--theme-color-white);

              @media screen and (min-width: breakpoint.$md) {
                width: 322px;
                height: 370px;
              }
            }

            p {
              max-width: 207px;
              padding-left: spacing.$s2;
              @media screen and (min-width: breakpoint.$md) {
                padding-left: spacing.$s4;
              }
            }
          }
        }
      }

      &__button {
        display: none;

        @media screen and (min-width: breakpoint.$md) {
          margin: 0;
          top: 0;
          position: absolute;
          display: flex;
          background-color: color.$base-60;
          transition: background-color transition.$default;

          &:hover {
            background-color: color.$base-70;
          }

          &__icon {
            fill: var(--theme-color-background);
          }
        }

        &--hidden {
          @media screen and (min-width: breakpoint.$md) {
            opacity: 0.2;
            cursor: not-allowed;

            &:hover {
              background-color: color.$base-60;
            }
          }

          @media screen and (min-width: breakpoint.$xxl) {
            display: none;
          }
        }

        &__prev {
          @media screen and (min-width: breakpoint.$md) {
            right: 53px;
            left: unset;
          }
        }

        &__next {
          @media screen and (min-width: breakpoint.$md) {
            right: 0;
          }
        }
      }
    }
  }
}
