@use '../../../../scss/variable/breakpoint';
@use '../../../../scss/variable/color';
@use '../../../../stories/scss/settings/spacing';

.ImageLayout {
  display: grid;
  overflow: hidden;
  position: relative;
  grid-row-gap: spacing.$s6;

  &_skeleton {
    border-radius: var(--theme-border-radius-md);
    background-color: color.$bg-grey;
    width: 100%;
    @media screen and (min-width: breakpoint.$lg) {
      width: auto;
    }
  }

  img {
    height: auto;
    border-radius: var(--theme-border-radius-md);

    @media screen and (min-width: breakpoint.$lg) {
      object-fit: contain;
    }
  }

  picture {
    display: flex;
  }

  &_one {
    display: none;
    &_small {
      grid-template-columns: 1fr;
    }

    &_large {
      grid-template-columns: 1fr;
    }
  }


  &_three {
    &_large {
      @media screen and (min-width: breakpoint.$lg) {
        grid-column-gap: 1.7%;
        grid-template-columns: 1fr 1fr;
      }

      &_items {
        grid-row-gap: spacing.$s6;
        @media screen and (min-width: breakpoint.$lg) {
          grid-row-gap: 3.6%;
        }
      }
    }

    &_small {
      @media screen and (min-width: breakpoint.$lg) {
        grid-column-gap: 1.7%;
        grid-template-columns: 66.2% auto;
      }

      &_items {
        grid-row-gap: spacing.$s6;
        @media screen and (min-width: breakpoint.$lg) {
          grid-row-gap: 5%;
        }
      }
    }

    &__items {
      display: grid;

      @media screen and (min-width: breakpoint.$lg) {
        grid-template-rows: 1fr 1fr;
      }

      &_item {
        position: relative;
      }
    }
  }
}
