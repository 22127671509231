@use '../../../../scss/variable/breakpoint';
@use '../../../../scss/variable/color';
@use '../../../../scss/variable/font';
@use '../../../../stories/scss/settings/spacing';

.Newsletter {
  @media screen and (min-width: breakpoint.$md) {
    display: grid;
    grid-column-gap: spacing.$s4;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'newsletter-content newsletter-usp'
      'newsletter-form newsletter-usp';
  }

  &__content {
    @media screen and (min-width: breakpoint.$md) {
      grid-area: newsletter-content;
      margin: 0;
      padding-left: spacing.$s4;
    }
  }

  &__usp {
    @media screen and (min-width: breakpoint.$md) {
      grid-area: newsletter-usp;
      padding-left: spacing.$s18;
      padding-top: spacing.$s4;
    }

    li {
      display: grid;
      grid-template-columns: auto 1fr;
      margin-bottom: 12px;
      column-gap: spacing.$s4;
      color: var(--theme-font-color-light);
      align-items: center;
    }
  }

  &__form {
    margin-top: spacing.$s8;

    @media screen and (min-width: breakpoint.$md) {
      grid-area: newsletter-form;
      max-width: 460px;
    }

    input {
      border-radius: 56px;
      padding-right: 120px;
      padding-left: spacing.$s6;
    }
  }

  button {
    right: 4px;
  }
}

