@use '../../../../scss/variable/breakpoint';
@use '../../../../scss/variable/color';
@use '../../../../scss/variable/font';
@use '../../../../stories/scss/settings/spacing';
@use '../../../../scss/mixin/container';
@use '../../../../scss/mixin/typography';

$bannerMobileHeight: 200px;
$bannerTabletHeight: 300px;
$bannerDesktopHeight: 464px;
$blocksDesktopWidth: 444px;

.Promotion {
  $root: &;
  display: grid;
  grid-column-gap: spacing.$s6;
  grid-template-columns: 1fr;

  @media screen and (min-width: breakpoint.$md) {
    grid-template-columns: 2fr 1fr;
  }

  @media screen and (min-width: breakpoint.$lg) {
    grid-template-columns: 2fr $blocksDesktopWidth;
  }

  &_right {
    @media screen and (min-width: breakpoint.$md) {
      grid-template-columns: 1fr 2fr;
    }

    @media screen and (min-width: breakpoint.$lg) {
      grid-template-columns: $blocksDesktopWidth 2fr;
    }

    @media screen and (min-width: breakpoint.$md) {
      #{$root}__banner {
        order: 2;
      }
    }
  }

  &_only {
    grid-template-columns: 1fr;
  }

  &__banner {
    margin-bottom: spacing.$s10;

    &_bottom {
      &_mobile {
        order: 2;
        margin: spacing.$s6 0 0;

        @media screen and (min-width: breakpoint.$md) {
          order: 0;
          margin: 0;
        }
      }
    }

    @media screen and (min-width: breakpoint.$md) {
      position: relative;
      margin-bottom: 0;
      height: $bannerDesktopHeight;
    }

    &__image {
      overflow: hidden;
      height: $bannerMobileHeight;

      @media screen and (min-width: breakpoint.$sm) {
        height: $bannerTabletHeight;
      }

      @media screen and (min-width: breakpoint.$md) {
        height: $bannerDesktopHeight;
        pointer-events: auto;
      }

      @media screen and (min-width: breakpoint.$md) {
        &_gradient {
          @include container.overlay-gradient(
            $_overlay-gradient-background: linear-gradient(to top, rgba(252, 248, 244, 0.7) 0%, rgba(252, 248, 244, 0) 55%),
            $_overlay-gradient-height: 100%
          );
        }
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      width: 270px;
      margin: 20px auto 0 auto;
      text-align: center;
      align-items: center;

      @media screen and (min-width: breakpoint.$md) {
        left: spacing.$s6;
        margin: 0;
        text-align: left;
        align-items: flex-start;
        padding: spacing.$s6;
        position: absolute;
        pointer-events: none;

        &:not(&_none) {
          background: rgba(252, 248, 244, 0.75);
          backdrop-filter: blur(3px);
          border-radius: var(--theme-border-radius-sm);
          max-width: calc(100% - #{spacing.$s6} * 2);
          width: auto;
        }
      }

      @media screen and (min-width: breakpoint.$md) {
        &:not(&_none) {
          > p {
            max-width: 300px;
          }
        }
      }

      @media screen and (min-width: breakpoint.$md) {
        &_none {
          width: 100%;
          bottom: 0;
          left: 0;
        }
      }

      @media screen and (min-width: breakpoint.$md) {
        &_top {
          top: spacing.$s6;
        }
      }

      @media screen and (min-width: breakpoint.$md) {
        &_center {
          top: 50%;
          transform: translateY(-50%);
        }
      }

      @media screen and (min-width: breakpoint.$md) {
        &_bottom {
          bottom: spacing.$s6;
        }
      }

      &__button {
        min-width: 170px;

        @media screen and (min-width: breakpoint.$md) {
          min-width: 140px;
          pointer-events: auto;
        }
      }
    }
  }

  &__blocks {
    $blockRoot: &;
    display: grid;

    &:not(&:empty){
      grid-row-gap: spacing.$s4;
    }

    @media screen and (max-width: breakpoint.$md) {
      &_asRow {
        gap: spacing.$s4;
        grid-template-rows: 1fr;
        grid-template-columns: repeat(2, 1fr);
      }
    }

    @media screen and (min-width: breakpoint.$md) {
      &:not(&:empty){
        grid-row-gap: spacing.$s6;
      }
    }

    &__item {
      display: flex;
      justify-content: space-between;
      min-height: 172px;

      @media screen and (max-width: breakpoint.$md) {
        #{$blockRoot}_asRow & {
          padding: spacing.$s4 0;
          flex-direction: column-reverse;
        }
      }

      @media screen and (min-width: breakpoint.$md) {
        padding: 40px 34px;
        height: calc((#{$bannerDesktopHeight} / 2) - (#{spacing.$s6} / 2));
      }

      &--green {
        background: color.$green-gradient;
      }

      &--red {
        background: color.$pink-gradient;
      }

      &--yellow {
        background: color.$yellow-gradient;
      }

      &--grey {
        background: var(--theme-color-base-light);
      }

      &--blue {
        background: color.$blue-gradient;
      }

      &__content {
        display: flex;
        margin: auto 0 0 0;
        justify-content: flex-end;

        #{$blockRoot}_asRow & {
          text-align: center;

          @media screen and (min-width: breakpoint.$md) {
            text-align: left;
          }
        }

        > p {
          margin-top: spacing.$s2;

          #{$blockRoot}_asRow & {
            @include typography.typography-ellipses(1);
          }
        }
      }

      &__image {
        margin: -16px 0;
        display: flex;
        align-self: flex-end;
        min-width: 140px;

        @media screen and (max-width: breakpoint.$md) {
          #{$blockRoot}_asRow & {
            align-self: center;
            margin: 0;
          }
        }

        @media screen and (min-width: breakpoint.$md) {
          display: none;
        }

        @media screen and (min-width: breakpoint.$lg) {
          margin: 0 16px 0 8px;
          display: flex;
          min-width: 156px;
        }

        img {
          height: auto;
        }
      }

      &:only-of-type {
        grid-row: 1 / 3;
      }
    }
  }
}
