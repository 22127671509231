@use '../../scss/settings/spacing';
@use '../../../scss/variable/font';
@use '../../../scss/variable/z-index';
@use '../../../scss/variable/breakpoint';
@use '../../scss/settings/components/settings.dialog' as dialog;

.Dialog {
  $root: &;
  max-height: dialog.$max-dialog-height;
  display: flex;
  padding: 0;
  background: var(--theme-color-background);
  color: var(--theme-font-color);
  border-radius: var(--theme-border-radius-xl);
  overflow: hidden;
  border: 0;
  outline: none;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  &_Centered {
    text-align: center;
  }

  @media screen and (max-width: breakpoint.$md) {
    max-width: dialog.$max-dialog-width;
  }

  &_Small {
    width: dialog.$dialog-size-sm;
  }

  &_Large {
    width: dialog.$dialog-size-lg;

    #{$root}__Footer {
      border-top: 1px solid var(--theme-color-base);
    }
  }

  &_FullHeight {
    height: 100%;
  }

  &_Unsupported {
    height: fit-content;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  // Wrapping div that prevents 'click outside' behaviour from happening inside the dialog.
  > div {
    width: 100%;
    display: flex;
  }

  &__Content {
    width: 100%;
    display: flex;
    flex-direction: column;

    &_NoPadding {
      padding: 0;

      #{$root}__Body {
        padding: 0;
      }

      #{$root}__closeButton {
        // Ensure there is enough contrast between the content background and the close button
        background-color: rgba(255, 255, 255, 0.75);
      }
    }

    &_HasClose:not(&_NoPadding) {
      padding-top: spacing.$s10;
    }
  }

  &__closeButton {
    position: absolute;
    right: spacing.$s6;
    top: spacing.$s6;
    z-index: z-index.$medium-1;
  }

  &__Header {
    padding: spacing.$s6;
    position: relative;
    pointer-events: none;

    // Ensure there is no padding between the header and the body
    &:has(+ .Dialog__Body) {
      padding-bottom: 0;
    }

    + .Dialog__Body {
      padding-top: 0;
    }

    > i {
      font-size: var(--theme-font-size-lg);
      color: var(--theme-font-color);
      padding: spacing.$s4 0;

      @media screen and (min-width: breakpoint.$md) {
        padding-top: 0;
      }
    }
  }

  &__Body {
    color: var(--theme-font-color-light);
    height: 100%;

    overflow-y: auto;
    padding: spacing.$s6;

    #{$root}_has_close & {
      :global(.h3) {
        display: block;
        padding: 0 spacing.$s10;
        margin-bottom: spacing.$s6;
      }
    }
  }

  &__Footer {
    padding: spacing.$s6;

    &_withBackground {
      background: var(--theme-color-background-dark);
    }
  }
}
