@use '../../../../scss/variable/breakpoint';
@use '../../../../scss/variable/color';
@use '../../../../scss/variable/font';
@use '../../../../stories/scss/settings/spacing';
@use '../../../../scss/variable/transition';
@use '../../../../scss/variable/shadow';

.Photoslurp {
  :global {
    /* stylelint-disable declaration-no-important, max-nesting-depth, selector-max-compound-selectors */
    .ps-carousel {
      .ps-carousel-slider {
        background-color: transparent !important;

        &.ps-slider-horizontal {
          padding: 0 !important;

          .ps-slider-container {
            margin: 0;
            overflow: visible;
            @media screen and (min-width: breakpoint.$lg) {
              overflow: hidden;
              margin: 0 spacing.$s18;
            }

            .ps-image {
              margin: 0 12px 0 0;

              @media screen and (min-width: breakpoint.$lg) {
                margin: 0 12px;
              }

              .ps-img-container {
                width: 240px !important;
                height: 300px !important;
                @media screen and (min-width: breakpoint.$lg) {
                  width: 280px !important;
                  height: 340px !important;
                }

                .ps-hover-overlay {
                  border-radius: var(--theme-border-radius-sm);

                  .ps-share {
                    border-radius: 0 0 var(--theme-border-radius-sm) var(--theme-border-radius-sm);
                    font-family: font.$primary !important;
                    text-transform: none !important;
                  }
                }

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }

                img,
                video {
                  border-radius: var(--theme-border-radius-sm);
                }
              }
            }
          }

          nav {
            z-index: 3;
            display: none;

            @media screen and (min-width: breakpoint.$lg) {
              position: absolute !important;
              bottom: auto !important;
              top: 50% !important;
              right: 0 !important;
              width: 100% !important;
              margin-top: -36px !important;
              display: flex;

              span {
                opacity: 1 !important;
                margin-top: 0 !important;
                text-indent: 0 !important;

                &.ps-prev {
                  &:after {
                    content: '\f053';
                  }
                }

                &.ps-next {
                  margin-left: auto !important;

                  &:after {
                    content: '\f054';
                  }
                }

                /// todo add as extend button
                &.ps-prev,
                &.ps-next {
                  $size: 56px;

                  position: relative !important;
                  top: auto !important;
                  right: auto !important;
                  left: auto !important;
                  bottom: auto !important;
                  padding: 0 !important;
                  line-height: $size !important;
                  border-radius: $size !important;
                  font-size: font.$size-base !important;
                  border: 0 !important;
                  text-align: center !important;
                  white-space: nowrap !important;
                  align-items: center !important;
                  justify-content: center !important;
                  width: $size !important;
                  height: $size !important;
                  color: var(--theme-font-color) !important;
                  background: var(--theme-color-white) !important;
                  box-shadow: shadow.$drop-bottom !important;
                  transition: transition.$default box-shadow !important;

                  &:after {
                    position: absolute;
                    top: 0;
                    font-family: font.$icon;
                    font-weight: 300;
                    font-size: font.$size-icon;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                  }

                  &:hover {
                    box-shadow: shadow.$drop-darker-bottom !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
