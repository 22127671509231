@use '../../../scss/variable/breakpoint';
@use '../../../stories/scss/settings/spacing';

.Block {
  border-radius: var(--theme-border-radius-sm);

  &--boxed {
    padding: spacing.$s8 spacing.$s2;
  }

  &--square {
    position: relative;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: var(--theme-border-radius-sm);
    }
  }

  &--link {
    position: relative;

    a {
      top: 0;
      left: 0;
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
}
