@use '../../../../stories/scss/settings/spacing';
@use '../../../../scss/variable/breakpoint';
@use '../../../../scss/mixin/container';

.Banner {
  $root: &;
  display: block;
  position: relative;
  overflow: hidden;
  height: 560px;

  @media screen and (min-width: breakpoint.$lg) {
    height: 620px;
  }

  &__image {

    picture {
      width: 100%;
      display: flex;
      overflow: hidden;

      img {
        width: 100%;
        object-fit: cover;
        filter: brightness(0.9);
      }
    }
  }

  &__container {
    @include container.wrapper;

    & {
      position: absolute;
      inset: 0;
    }

    #{$root}__content {
      position: absolute;
      bottom: 88px;
      display: flex;
      flex-direction: column;
      max-width: 500px;

      &_title {
        color: var(--theme-element-color-primary);
        margin-bottom: spacing.$s6;
        font-weight: 600;

        @media screen and (min-width: breakpoint.$lg) {
          font-size: 60px;
        }
      }

      &_subtitle {
        color: var(--theme-element-color-primary);
        margin-bottom: spacing.$s10;
      }
    }
  }
}
